<template>
  <div class="pa-2 ">
    <page-card
        cardTitle="Front Cover"
        cardTitleIcon="mdi-book-open-page-variant"
    >

      <v-card-text class="pb-2">
        <p>You have chosen Custom Covers, where you can upload your own front and back covers for your booklet. Once you
          have these ready, you need to come back to this section and upload the files so that they can be inserted into
          your booklet.</p>
        <p>Below are some guidelines for the cover designs. Whilst this may seem a daunting list, it will ensure that
          the covers are of the same quality and standard that feature throughout the inside pages of the booklet.</p>
        <ul>
          <li>Designs must be 210mm by 210mm with a 3mm bleed. (213mm x 213mm w/bleed)</li>
          <li>Text on the document must not exceed the bleed margin</li>
          <li>The document format must be in CMYK</li>
          <li>When exporting, the document should be at least 300dpi</li>
          <li>The document needs to be exported as a JPEG</li>
        </ul>
        <br><p>We would recommend:</p>
        <ul>
          <li>Any text to be 10mm away from the page edge</li>
          <li>That you don't use a decorative font for small information text – this can make it hard to read!</li>
        </ul>
      </v-card-text>

      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="submit"
          class="pa-4"
          :disabled="readOnlyMode"
      >

        <image-upload
            ref="imageUpload"
            sourceType="page"
            :sourceId=pageName
            fileInputLabel="Choose front cover image"
        />

        <div class="mt-8">
          <v-btn
              :disabled="!valid || readOnlyMode"
              type="submit"
              color="green"
              class="mr-4"
          >
            Save
          </v-btn>
          <v-btn :disabled="readOnlyMode" @click="clear" class="mr-4">Clear</v-btn>
          <v-btn :disabled="!valid" @click="preview">Preview</v-btn>
        </div>

      </v-form>

    </page-card>
  </div>
</template>


<script>
import ImageUpload from "@/components/ImageUpload";
import PageCard from "@/components/PageCard";
import {form} from "@/mixins/form";

export default {
  name: "Front",
  components: {
    ImageUpload,
    PageCard
  },
  mixins: [form],
  data() {
    return {
      pageName: "cover",
      valid: true,
    }
  },
}
</script>
